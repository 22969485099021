/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import closeIcon from "../assets/close@2x.png";
import "./offcanvas.css";

export const Navigation = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the value as needed
    };

    // Initial check
    handleResize();

    // Add a listener to respond to window resize events
    window.addEventListener("resize", handleResize);

    // Clean up the listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!isMobile) {
      setIsMenuOpen(false);
    }
  }, [isMobile]);

  return (
    <div>
      <nav
        id="menu"
        className="navbar navbar-default navbar-fixed-top"
        color="blue"
      >
        <div className="container">
          <div className="navbar-header">
            <button
              onClick={toggleMenu}
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              {" "}
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>
            <a href="#page-top">
              <img
                className="img-container"
                src="img/logoo.png"
                alt="logo"
              ></img>
            </a>{" "}
          </div>
          
          <div
            className="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
          >
            <ul className="nav navbar-nav navbar-right">
              <li>
                <a href="#about">About</a>
              </li>
              <li>
                <a href="#about">Vision</a>
              </li>
              <li>
                <a href="#services">Services</a>
              </li>
              <li>
                <a href="#careers">Careers</a>
              </li>
              {/* <li>
              <a href="#team" >
              Team
              </a>
            </li> */}
              <li>
                <a href="#contact">Contact</a>
              </li>
              {/*<li>  
                <a href="https://wa.me/923303333334" >
                  <img className="wimg-container" src="img/whatsapp.png" alt="whatsapp"></img>
                </a>
              </li> */}          
            </ul>
          </div>
        </div>
      </nav>
      {/* Off canva menu */}
      <div className={`off-canvas-menu ${isMenuOpen ? "open" : ""}`}>
        <ul onClick={toggleMenu}>
          <li>
            <a href="#about">About</a>
          </li>
          <li>
            <a href="#services">Services</a>
          </li>
          <li>
            <a href="#about">Vision</a>
          </li>
          <li>
            <a href="#careers">Careers</a>
          </li>
          <li>
            <a href="#contact">Contact</a>
          </li>
        </ul>
        <div className="close-image">
          <img
            onClick={toggleMenu}
            src={closeIcon}
            width="100%"
            height="100%"
          />
        </div>
      </div>
    </div>
  );
};
