import React from 'react';

const MaintenancePage = (props) =>
{
  return (
    <div id="undermainteinance" className="backgroudum text-center">
      <div className="container-u-m">
        <div className="section-title">
          <h2>ERROR 404!</h2>
          <div className="error_details">

          </div>x
        </div>
      </div>
    </div>
  );
  // return (
  //   <div style={styles.container}>
  //     <h1 style={styles.header}>We'll Be Back Soon!</h1>
  //     <p style={styles.text}>
  //       Our site is currently under maintenance. We're working hard to improve your experience. Please check back later.
  //     </p>
  //     <p style={styles.text}>Thank you for your patience!</p>
  //   </div>
  // );
};

// const styles = {
//   container: {
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     justifyContent: 'center',
//     height: '100vh',
//     textAlign: 'center',
//     backgroundColor: '#f8f8f8',
//     color: '#333',
//     padding: '20px',
//   },
//   header: {
//     fontSize: '2.5rem',
//     marginBottom: '20px',
//   },
//   text: {
//     fontSize: '1.2rem',
//     marginBottom: '10px',
//   },
// };

export default MaintenancePage;
